import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Checkbox, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Select, SelectChangeEvent } from '@mui/material';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { ApiUrls } from '../../constants/ApiUrls';
import { OutputFiles } from '../../constants/OutputFiles';
import { Permissions } from '../../constants/Permissions';
import { useActingFor } from '../../hooks/useActingFor';
import { makePostBlobRequest } from '../../services/ajax/ajax';
import { getUserAcnName, hasPermissions } from '../../services/auth/auth';
import { getLabel } from '../common/label/Label.library';
import './CycleSnapshot.css';

interface Props {
    cycleId: number;
    isCycleUnlocked: boolean;
}

const snapshotRadio_AllACNs = 0;
const snapshotRadio_SelectedACN = 1;

export const CycleSnapshot: React.FC<Props> = ({ cycleId, isCycleUnlocked }) => {
    const dispatch = useDispatch();
    const acnActingFor = useActingFor();
    const isMAUser = hasPermissions(Permissions.CAN_VIEW_ALL_CYCLE_PARTICIPATION_DATA); //same for Admin
    const isPHUser = hasPermissions(Permissions.CAN_VIEW_CLIENTS_CYCLE_PARTICIPATION_DATA);
    const isACNUser = hasPermissions(Permissions.CAN_VIEW_ACNS_CYCLE_PARTICIPATION_DATA);
    const canDownloadSnapshot = isMAUser || isPHUser || isACNUser;
    const hasMultipleACNs = !getUserAcnName();
    const [snapshotRadio, setSnapshotRadio] = useState<number>(hasMultipleACNs ? snapshotRadio_AllACNs : snapshotRadio_SelectedACN);
    const [snapshotFormat, setSnapshotFormat] = useState<number>(0);
    const [includeUnattested, setIncludeUnattested] = useState<boolean>(true);
    const [includeUnaffiliated, setIncludeUnaffiliated] = useState<boolean>(true);
    const [downloading, setDownloading] = useState<boolean>(false);

    const includeUnattestedForced = () => (snapshotRadio == snapshotRadio_SelectedACN) || isCycleUnlocked;
    const includeUnattestedActual = () => includeUnattested || includeUnattestedForced();
    const includeUnaffiliatedPossible = () => (snapshotRadio == snapshotRadio_AllACNs) && (isMAUser || isPHUser) && includeUnattestedActual();
    const includeUnaffiliatedActual = () => includeUnaffiliated && includeUnaffiliatedPossible();

    const downloadSnapshot = async () => {
        var outputFile = OutputFiles.CYCLE_SNAPSHOT_BASE; //TODO: see #53257 if we ever touch this "output filename" logic
        outputFile = includeUnattestedActual() ? outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_UNATTESTED) : outputFile;
        switch (snapshotFormat) {
            case 0:
                outputFile = outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_CSV);
                break;
            case 1:
                outputFile = outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_PIPE_DELIMITED);
                break;
            default:
                outputFile = outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_CSV);
                break;
        }
        const data = {
            cycleId: cycleId,
            acnId: snapshotRadio === snapshotRadio_AllACNs ? -1 : acnActingFor.id,
            format: snapshotFormat,
            includeUnattested: includeUnattestedActual(),
            includeUnaffiliated: includeUnaffiliatedActual()
        }
        setDownloading(true);
        if (isMAUser) {
            await makePostBlobRequest(ApiUrls.DOWNLOAD_CYCLE_PARTICIPATION_AS_ADMIN, dispatch, outputFile, data, true, true);
        } else if (isPHUser) {
            await makePostBlobRequest(ApiUrls.DOWNLOAD_CYCLE_PARTICIPATION_AS_CLIENT, dispatch, outputFile, data, true, true);
        } else {
            await makePostBlobRequest(ApiUrls.DOWNLOAD_CYCLE_PARTICIPATION_AS_ACN_USER, dispatch, outputFile, data, true, true);
        }
        dispatch(SetUserMessageSuccessAction('cycle_snapshot_prepared'));
        setDownloading(false);
    };

    return (<>
        <h2 className="cycle-snapshot-label">{getLabel('cycle_snapshot_label')}</h2>
        <Card className="cycle-snapshot grid">
            <FormControl variant="outlined" className="snapshot-dropdown" size="small">
                <InputLabel id="snapshotFormatLabel" htmlFor="SnapshotFormat">{getLabel("cycle_snapshot_format")}</InputLabel>
                <Select native labelId="snapshotFormatLabel" label={getLabel("cycle_snapshot_format")}
                    value={snapshotFormat} onChange={(event: SelectChangeEvent<number>) => { setSnapshotFormat(+event.target.value) }}
                    id="SnapshotFormat" name="SnapshotFormat">
                    <option key={0} value={0}>{getLabel("cycle_snapshot_format_csv")}</option>
                    {(hasPermissions(Permissions.CAN_EXPORT_PIPE_DELIMITED_SNAPSHOT)) && <option key={1} value={1}>{getLabel("cycle_snapshot_format_pipe")}</option>}
                </Select>
            </FormControl>
            <FormControl className="snapshot-radio">
                <RadioGroup value={snapshotRadio} onChange={(_, value) => setSnapshotRadio(+value)}>
                    {hasMultipleACNs && <FormControlLabel value={snapshotRadio_AllACNs}
                        control={<Radio color="primary" />} label={(isMAUser || isPHUser)
                            ? getLabel("cycle_snapshot_radio_allAcns")
                            : getLabel("cycle_snapshot_radio_allMyAcns")} />}
                    <FormControlLabel value={snapshotRadio_SelectedACN}
                        control={<Radio color="primary" />} label={getLabel("cycle_snapshot_radio_selectedAcns")} />
                </RadioGroup>
            </FormControl>
            <div className="snapshot-checks flexColumn">
                <FormControlLabel
                    control={<Checkbox color="primary" name="includeUnattested" onChange={(_, value) => setIncludeUnattested(value)}
                        checked={includeUnattestedActual()}
                        disabled={includeUnattestedForced()} />}
                    label={getLabel("cycle_snapshot_include_unattested")} />
                {(isMAUser || isPHUser) && <FormControlLabel
                    control={<Checkbox color="primary" name="includeUnaffiliated" onChange={(_, value) => setIncludeUnaffiliated(value)}
                        checked={includeUnaffiliatedActual()}
                        disabled={!includeUnaffiliatedPossible()} />}
                    label={getLabel("cycle_snapshot_include_unaffiliated")} />}
            </div>
            {canDownloadSnapshot && <Button className="button green-button" type="button" variant="contained" color="primary"
                onClick={downloadSnapshot} disabled={downloading}>{getLabel('edit_cycle_download_snapshot_label')}</Button>}
        </Card>
    </>)
};
