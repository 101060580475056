import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CycleInfo } from '../../components/cycles/CycleAttributes';
import { CyclePrograms } from '../../components/cycles/CyclePrograms';
import { CycleAttestation } from '../../components/cycles/CycleAttestation';
import { ViewCycleInfo } from '../../components/cycles/ViewCycleAttributes';
import './Cycles.css';
import { getLabel } from '../../components/common/label/Label.library';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { TabValue } from '../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { Cycle } from '../../interfaces/ApiInterfaces';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';
import { formatEditDate } from '../../services/format/date';
import { CycleSnapshot } from '../../components/cycles/CycleSnapshot';

export const EditCycle: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();
    const cycleId = +params.id!;
    const [cycle, setCycle] = useState<Cycle>();

    useEffect(() => {
        document.title = cycleId === -1
            ? `${getLabel('app_name')} ${getLabel('title_add_cycle')}`
            : `${getLabel('app_name')} ${getLabel('title_edit_cycle')}`;
    }, []);

    useEffect(() => {
        getCycle();
    }, [cycleId, dispatch]);

    const getCycle = async () => {
        if (cycleId !== -1) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_CYCLE, { cycleId }), dispatch, null, false, false).then((cycleResponse) => {
                var cycle = cycleResponse.body;
                setCycle({
                    id: cycle.id,
                    name: cycle.name,
                    startAt: formatEditDate(cycle.startAt),
                    endAt: formatEditDate(cycle.endAt),
                    snapshotAt: formatEditDate(cycle.snapshotAt),
                    isOpen: cycle.isOpen,
                    locked: cycle.locked,
                    dedicatedAcnId: cycle.dedicatedACNId
                });
            });
        }
        else {
            setCycle({ id: -1, name: '', startAt: '', endAt: '', snapshotAt: '', isOpen: true, locked: false, dedicatedAcnId: undefined });
        }
    }

    useEffect(() => {
        if (params.tab === TabValue.CYCLE_ATTRIBUTES) {
            setTabValue(0);
        }
        else if (params.tab === TabValue.CYCLE_PROGRAMS && cycleId !== -1) {
            setTabValue(1);
        }
        else if (params.tab === TabValue.CYCLE_ATTESTATION && cycleId !== -1) {
            setTabValue(2);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [cycleId, params.tab]);

    function getAddEditViewLabel() {
        var label;
        if (cycleId === -1) {
            label = getLabel('cycle_add_page_heading');
        } else {
            label = getLabel(hasPermissions(Permissions.CAN_UPDATE_CYCLE)
                ? 'cycle_edit_page_heading'
                : 'cycle_view_page_heading', { name: cycle!.name });
        }
        return label;
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_CYCLE;
        } else if (tabValue === 1) {
            return InfoTitles.CYCLE_PROGRAMS;
        } else {
            return InfoTitles.CYCLE_ATTESTATION;
        }
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_PROGRAMS }))
        }
        else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_ATTESTATION }))
        }
        setTabValue(value);
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="cycle-attributes-container">
                {!!cycle &&
                    <>
                        <h2><PageHeading to={createRoute(ApplicationRoutes.CYCLES)} parentHeading={getLabel('cycle_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="user-attributes-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} aria-label="simple tabs example" indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label={getLabel('tab_label_attributes')} value={0} />
                                    <Tab label={getLabel('tab_label_programs')} value={1} disabled={cycleId === -1 || !hasPermissions(Permissions.CAN_VIEW_PROGRAMS)} />
                                    <Tab label={getLabel('tab_label_attestation')} value={2} disabled={cycleId === -1} />
                                </Tabs>
                            </AppBar>
                        {tabValue === 0 && (hasPermissions(Permissions.CAN_UPDATE_CYCLE) ? (<CycleInfo cycle={cycle} getCycle={getCycle}/>) : (<ViewCycleInfo cycle={cycle}/>))}
                            {tabValue === 1 && <CyclePrograms cycle={cycle} />}
                            {tabValue === 2 && <CycleAttestation cycle={cycle} />}
                    </Card>
                    {cycleId !== -1 && <CycleSnapshot cycleId={cycleId} isCycleUnlocked={!cycle.locked} />}
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};